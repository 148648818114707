
import ViewModel from '@/models/ViewModel'
import { Component, Ref, Watch } from 'vue-property-decorator'
import DataTable from '@/components/DataTable/index.vue'
import Widget from '@/components/Widget/Widget.vue'
import SearchInput from '@/components/SearchInput/SearchInput.vue'
import CompanyContact from '@/models/CompanyContact'
import { getModule } from 'vuex-module-decorators'
import SystemtModule from '@/store/SystemModule'
import IconAction from '@/components/IconAction/IconAction.vue'
import company_contact_fields from './company-contact-home-fields'

@Component({
  components: {
    DataTable,
    Widget,
    SearchInput,
    IconAction,
  },
})
export default class CompanyContactHome extends ViewModel {
  @Ref() readonly dataTable!: HTMLFormElement

  public query: string[] = []

  public fields: any = []

  public page_size: number = 25

  public sort_by: string = 'updated_at'

  public sort_desc: boolean = false

  public page: number = 1

  public records: number = 0

  public loading: boolean = true

  public ready: boolean = false

  public query_settings: any = {
    company_fields: [
      {
        name: 'Company',
        key: 'company_id',
      },
    ],
  }

  public fieldFilters: any = {
    created_at: '',
    updated_at: '',
  }

  public filter_mode: string = 'exclusive'

  @Watch('filter_mode')
  public onFilterModeChange() {
    this.refresh()
  }

  public get show_fields() {
    return this.fields.filter((f: any) => f.show)
  }

  public mounted() {
    this.fields = company_contact_fields
    this.loadFilters()
  }

  public loadData(context: any) {
    this.loading = true

    const field_filters = Object.keys(this.fieldFilters)
      .filter((key: string) => this.fieldFilters[key] !== '')
      .map((key: string) => `${key}:${this.fieldFilters[key].toLowerCase()}`)
    this.syncFilters()
    return CompanyContact.paginate({
      page_size: context.perPage,
      page: context.currentPage,
      order_by: context.sortBy,
      order: context.sortDesc ? 'desc' : 'asc',
      query: [...context.filter, ...field_filters],
    }).then(result => {
      this.records = result.records
      this.loading = false
      return result.data
    })
  }

  public newContact() {
    this.$router.push({ name: 'company-contact' })
  }

  public deleteContact(item: any) {
    item.delete().then(() => {
      this.refresh()
    })
  }

  // Store session filters in VUEX
  public syncFilters() {
    const system = getModule(SystemtModule)
    system.updateState({
      name: 'filters',
      type: 'companies',
      data: { query: this.query, fieldFilters: this.fieldFilters },
    })
  }

  // Load filters from VUEX if present
  public loadFilters() {
    const system = getModule(SystemtModule)
    system.getFilter('companies').then((filter: any) => {
      if (filter) {
        this.query = filter.query
        this.fieldFilters = filter.fieldFilters
      }
      this.ready = true
    })
  }

  // Clear VUEX filters state
  public clearFilters() {
    const system = getModule(SystemtModule)
    system.updateState({
      name: 'filters',
      type: 'companies',
      data: null,
    })
  }

  // Refresh dataTable
  public refresh() {
    this.dataTable.refresh()
  }

  // Reset filters
  public resetFilters() {
    this.fieldFilters = {
      created_at: '',
      updated_at: '',
    }
    this.query = []
    this.clearFilters()
    this.refresh()
  }
}
