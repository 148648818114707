import router from '@/Routes'
import CompanyContact from '@/models/CompanyContact'

export default [
  {
    key: 'id',
    label: 'Id',
    sortable: true,
    show: false,
    class: 'text-center align-middle',
  },
  {
    key: 'company',
    label: 'Company',
    type: 'object',
    object: {
      key: 'company',
      property: 'name',
    },
    sortable: true,
    show: true,
    class: 'text-center align-middle',
  },
  {
    key: 'salutation',
    label: 'Salutation',
    sortable: true,
    show: true,
    class: 'text-center text-capitalize align-middle',
    formatter: (value: any) => (value || 'N/A'),
  },
  {
    key: 'first_name',
    label: 'First Name',
    sortable: true,
    show: true,
    class: 'text-center text-capitalize align-middle',
  },
  {
    key: 'last_name',
    label: 'Last Name',
    sortable: true,
    show: true,
    class: 'text-center text-capitalize align-middle',
  },
  {
    key: 'email',
    label: 'Email',
    sortable: true,
    show: true,
    class: 'text-center align-middle',
  },
  {
    key: 'title',
    label: 'Title',
    sortable: true,
    show: true,
    class: 'text-center text-capitalize align-middle',
  },
  {
    key: 'owner',
    label: 'Owner',
    type: 'object',
    object: {
      key: 'owner',
      property: 'name',
    },
    sortable: true,
    show: true,
    class: 'text-center align-middle',
  },
  {
    key: 'created_at',
    label: 'Created At',
    thClass: 'align-middle text-center',
    sortable: true,
    class: 'text-center',
    show: false,
    filter_type: 'date',
    type: 'date',
  },
  {
    key: 'updated_at',
    label: 'Update At',
    thClass: 'align-middle text-center',
    sortable: true,
    class: 'text-center',
    show: true,
    filter_type: 'date',
    type: 'date',
  },
  {
    key: 'action',
    label: '',
    sortable: false,
    show: true,
    type: 'action_list',
    actions: [
      {
        icon: 'pencil-alt',
        title: 'Edit Contact',
        event: 'edit',
      },
      {
        icon: 'trash',
        title: 'Delete Contact',
        event: 'delete',
      },
    ],
  },
]
